import React, { useState, useEffect } from 'react';

import './App.css';

// prettier-ignore
const UNSPLASH_CLIENT_ID = '735383e17276cf0667bf1d7c0755d0e19d38ff8989e939454a0ac984c7afefea';

async function fetchPhotosMetadata(photos) {
	if (!photos.length) {
		return photos;
	}

	const copy = [...photos];
	const requests = [];

	copy.forEach(photo => {
		requests.push(
			fetch(
				`https://api.unsplash.com/photos/${photo.id}/statistics?client_id=${UNSPLASH_CLIENT_ID}`
			).then(res => res.json())
		);
	});

	const metadata = await Promise.all(requests);

	return copy.map((photo, i) => ({
		...photo,
		...metadata[i]
	}));
}

const Carousel = () => {
	const [error, setError] = useState('');
	const [photos, setPhotos] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (currentIndex % 9 !== 0) {
			return;
		}

		fetch(
			`https://api.unsplash.com/search/photos?&query=cat&client_id=${UNSPLASH_CLIENT_ID}&page=${currentPage}`
		)
			.then(res => res.json())
			.then(async data => {
				const photosWithMetadata = await fetchPhotosMetadata(
					data.results
				);

				setPhotos([...photos, ...photosWithMetadata]);
				setTotalPages(data.total);
			})
			.catch(e => setError('An error occured.'));
		//eslint-disable-next-line
	}, [currentIndex]);

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div className="carousel">
			<button
				type="button"
				className="carousel__control carousel__control--prev"
				disabled={currentIndex === 0}
				aria-label="Previous slide"
				onClick={() => {
					setCurrentIndex(currentIndex - 1);
				}}
			>
				Previous slide
			</button>

			<button
				type="button"
				className="carousel__control carousel__control--next"
				aria-label="Next slide"
				disabled={currentPage === totalPages - 1}
				onClick={() => {
					setCurrentIndex(currentIndex + 1);

					if (currentIndex % 10 === 0) {
						setCurrentPage(currentPage + 1);
					}
				}}
			>
				Next slide
			</button>

			<div className="carousel__wrapper">
				<div
					className="carousel__slides"
					style={{
						transform: `translateX(-${currentIndex * 100}%)`
					}}
				>
					{photos.map((photo, key) => (
						<div
							className="carousel__slide"
							key={key}
							style={{
								backgroundImage: `url(${photo.urls.regular})`
							}}
						>
							<div className="carousel__slide-meta">
								<p>Likes: {photo.likes.total}</p>

								<p>Views: {photo.views.total}</p>

								<p>Downloads: {photo.downloads.total}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Carousel;
